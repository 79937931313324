<template>
  <div class="animated fadeIn">
    <b-card header="Producción">
      <b-row>
        <b-col md="3">
          <b-card header="Siembra">
            <siembra :height="300" class="chart-wrapper"></siembra>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Cosecha">
            <cosecha :height="300" class="chart-wrapper"></cosecha>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Procesamiento en Planta">
            <procesamiento :height="300" class="chart-wrapper"></procesamiento>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Mortalidad">
            <mortalidad :height="300" class="chart-wrapper"></mortalidad>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Uso de Antibioticos">
            <antibioticos :height="150" class="chart-wrapper"></antibioticos>
            <relacion-antibioticos :height="150" class="chart-wrapper"></relacion-antibioticos>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Alimentos">
            <alimentos :height="300" class="chart-wrapper"></alimentos>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Alimentos Consumidos">
            <alimentos-consumidos :height="300" class="chart-wrapper"></alimentos-consumidos>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Legal">
      <b-row>
        <b-col md="3">
          <b-card header="Sanciones Totales">
            <sanciones :height="300" class="chart-wrapper"></sanciones>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Ambiental">
            <ambiental :height="300" class="chart-wrapper"></ambiental>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Laboral">
            <laboral :height="300" class="chart-wrapper"></laboral>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Municipal">
            <municipal :height="300" class="chart-wrapper"></municipal>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Biodiversidad">
      <b-row>
        <b-col md="6">
          <b-card header="Maxima deposición estimada en fondo marino">
            <deposiciones :height="150" class="chart-wrapper"></deposiciones>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Agua">
      <b-row>
        <b-col md="3">
          <b-card header="Uso en Piscicultura (m3)">
            <psicicultura :height="300" class="chart-wrapper"></psicicultura>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Recicleje (m3)">
            <reciclaje :height="300" class="chart-wrapper"></reciclaje>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Chaparro">
            <chaparro :height="300" class="chart-wrapper"></chaparro>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Molco">
            <molco :height="300" class="chart-wrapper"></molco>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Puerto Fonck">
            <fonck :height="300" class="chart-wrapper"></fonck>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Uso de Plantas (m3)">
            <uso-plantas :height="300" class="chart-wrapper"></uso-plantas>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Gestión de Residuos">
      <b-row>
        <b-col md="3">
          <b-card header="Total de Residuos">
            <total-residuos :height="300" class="chart-wrapper"></total-residuos>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Generadores">
            <generadores :height="300" class="chart-wrapper"></generadores>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Envio a Relleno Sanitario">
            <relleno :height="300" class="chart-wrapper"></relleno>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Gestión de Reciclaje Farming">
            <gestion-reciclaje :height="300" class="chart-wrapper"></gestion-reciclaje>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Emisiones GEI">
      <b-row>
        <b-col md="3">
          <b-card header="Total de Emisiones GEI">
            <total-emisiones :height="300" class="chart-wrapper"></total-emisiones>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Alcances">
            <alcances :height="300" class="chart-wrapper"></alcances>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Huella de Carbono">
            <huella :height="300" class="chart-wrapper"></huella>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Empleo">
      <b-row>
        <b-col md="3">
          <b-card header="Total de Trabajadores">
            <trabajadores :height="300" class="chart-wrapper"></trabajadores>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Trabajores por Sexo">
            <sexo :height="300" class="chart-wrapper"></sexo>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Sindicato (2018)">
            <sindicato :height="300" class="chart-wrapper"></sindicato>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Tasa de Accidentabilidad">
            <accidentes :height="300" class="chart-wrapper"></accidentes>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Tasa de Fatalidad">
            <fatalidad :height="300" class="chart-wrapper"></fatalidad>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Proveedores">
      <b-row>
        <b-col md="6">
          <b-card header="Total de Compras">
            <total-compras :height="150" class="chart-wrapper"></total-compras>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Compras en Chile (2018)">
            <porcentaje-compras :height="150" class="chart-wrapper"></porcentaje-compras>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Comunidad">
      <b-row>
        <b-col md="6">
          <b-card header="Total de Inversión Social">
            <total-inversion :height="150" class="chart-wrapper"></total-inversion>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Inversiones">
            <inversiones :height="150" class="chart-wrapper"></inversiones>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
    import CardLine1ChartExample from './dashboard/CardLine1ChartExample'
    import CardLine2ChartExample from './dashboard/CardLine2ChartExample'
    import CardLine3ChartExample from './dashboard/CardLine3ChartExample'
    import CardBarChartExample from './dashboard/CardBarChartExample'
    import MainChartExample from './dashboard/MainChartExample'
    import SocialBoxChartExample from './dashboard/SocialBoxChartExample'
    import CalloutChartExample from './dashboard/CalloutChartExample'
    import {Callout} from '@coreui/vue'
    import Siembra from "./indicadores/produccion/Siembra";
    import Cosecha from "./indicadores/produccion/Cosecha";
    import Procesamiento from "./indicadores/produccion/Procesamiento";
    import Mortalidad from "./indicadores/produccion/Mortalidad";
    import Antibioticos from "./indicadores/produccion/Antibioticos";
    import RelacionAntibioticos from "./indicadores/produccion/RelacionAntibioticos";
    import Alimentos from "./indicadores/produccion/Alimentos";
    import AlimentosConsumidos from "./indicadores/produccion/AlimentosConsumidos";
    import Sanciones from "./indicadores/legal/Sanciones";
    import Ambiental from "./indicadores/legal/Ambiental";
    import Laboral from "./indicadores/legal/Laboral";
    import Municipal from "./indicadores/legal/Municipal";
    import Deposiciones from "./indicadores/biodiversidad/Deposiciones";
    import Reciclaje from "./indicadores/agua/Reciclaje";
    import Psicicultura from "./indicadores/agua/Psicicultura";
    import Chaparro from "./indicadores/agua/Chaparro";
    import Molco from "./indicadores/agua/Molco";
    import Fonck from "./indicadores/agua/Fonck";
    import UsoPlantas from "./indicadores/agua/UsoPlantas";
    import Generadores from "./indicadores/residuos/Generadores";
    import Relleno from "./indicadores/residuos/Relleno";
    import GestionReciclaje from "./indicadores/residuos/GestionReciclaje";
    import TotalEmisiones from "./indicadores/emisiones/TotalEmisiones";
    import Alcances from "./indicadores/emisiones/Alcances";
    import Huella from "./indicadores/emisiones/Huella";
    import Trabajadores from "./indicadores/empleo/Trabajadores";
    import Sexo from "./indicadores/empleo/Sexo";
    import Sindicato from "./indicadores/empleo/Sindicato";
    import Accidentes from "./indicadores/empleo/Accidentes";
    import Fatalidad from "./indicadores/empleo/Fatalidad";
    import TotalCompras from "./indicadores/proveedores/TotalCompras";
    import PorcentajeCompras from "./indicadores/proveedores/PorcentajeCompras";
    import TotalInversion from "./indicadores/comunidad/TotalInversion";
    import Inversiones from "./indicadores/comunidad/Inversiones";
    import TotalResiduos from "./indicadores/residuos/TotalResiduos";


    export default {
  name: 'dashboard',
  components: {
      TotalResiduos,
      Inversiones,
      TotalInversion,
      PorcentajeCompras,
      TotalCompras,
      Fatalidad,
      Accidentes,
      Sindicato,
      Sexo,
      Trabajadores,
      Huella,
      Alcances,
      TotalEmisiones,
      GestionReciclaje,
      Relleno,
      Generadores,
      UsoPlantas,
      Fonck,
      Molco,
      Chaparro,
      Psicicultura,
      Reciclaje,
      Deposiciones,
      Municipal,
      Laboral,
      Ambiental,
      Sanciones,
      AlimentosConsumidos,
      Alimentos,
      RelacionAntibioticos,
      Antibioticos,
      Mortalidad,
      Procesamiento,
      Cosecha,
      Siembra,
    Callout,
    CardLine1ChartExample,
    CardLine2ChartExample,
    CardLine3ChartExample,
    CardBarChartExample,
    MainChartExample,
    SocialBoxChartExample,
    CalloutChartExample
  },
  data: function () {
    return {
      selected: 'Month',
      tableItems: [
        {
          avatar: { url: 'img/avatars/1.jpg', status: 'success' },
          user: { name: 'Yiorgos Avraamu', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'USA', flag: 'us' },
          usage: { value: 50, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Mastercard', icon: 'fa fa-cc-mastercard' },
          activity: '10 sec ago'
        },
        {
          avatar: { url: 'img/avatars/2.jpg', status: 'danger' },
          user: { name: 'Avram Tarasios', new: false, registered: 'Jan 1, 2015' },
          country: { name: 'Brazil', flag: 'br' },
          usage: { value: 22, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Visa', icon: 'fa fa-cc-visa' },
          activity: '5 minutes ago'
        },
        {
          avatar: { url: 'img/avatars/3.jpg', status: 'warning' },
          user: { name: 'Quintin Ed', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'India', flag: 'in' },
          usage: { value: 74, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Stripe', icon: 'fa fa-cc-stripe' },
          activity: '1 hour ago'
        },
        {
          avatar: { url: 'img/avatars/4.jpg', status: '' },
          user: { name: 'Enéas Kwadwo', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'France', flag: 'fr' },
          usage: { value: 98, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'PayPal', icon: 'fa fa-paypal' },
          activity: 'Last month'
        },
        {
          avatar: { url: 'img/avatars/5.jpg', status: 'success' },
          user: { name: 'Agapetus Tadeáš', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'Spain', flag: 'es' },
          usage: { value: 22, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Google Wallet', icon: 'fa fa-google-wallet' },
          activity: 'Last week'
        },
        {
          avatar: { url: 'img/avatars/6.jpg', status: 'danger' },
          user: { name: 'Friderik Dávid', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'Poland', flag: 'pl' },
          usage: { value: 43, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Amex', icon: 'fa fa-cc-amex' },
          activity: 'Last week'
        }
      ],
      tableFields: {
        avatar: {
          label: '<i class="icon-people"></i>',
          class: 'text-center'
        },
        user: {
          label: 'User'
        },
        country: {
          label: 'Country',
          class: 'text-center'
        },
        usage: {
          label: 'Usage'
        },
        payment: {
          label: 'Payment method',
          class: 'text-center'
        },
        activity: {
          label: 'Activity'
        }
      }
    }
  },
  methods: {
    variant (value) {
      let $variant
      if (value <= 25) {
        $variant = 'info'
      } else if (value > 25 && value <= 50) {
        $variant = 'success'
      } else if (value > 50 && value <= 75) {
        $variant = 'warning'
      } else if (value > 75 && value <= 100) {
        $variant = 'danger'
      }
      return $variant
    },
    flag (value) {
      return 'flag-icon flag-icon-' + value
    }
  }
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
</style>
